<template>
<v-container style="background-color:#f5f8fa;">
  <v-row>
    <span class="text-h5 pa-5">
      开启高级功能
    </span>
  </v-row>
  <v-row>
    <span class="pl-5 pb-3">
      根据企业管理的个性化需要，请开启或关闭以下设置
    </span>
  </v-row>
  <v-row>
    <v-container style="background-color:#fff;">
      <v-tabs v-model="tab" icons-and-text>
        <v-tab>
          任务/计划设置
        </v-tab>
        <v-tab>
          项目设置
        </v-tab>
        <v-tab>
          总结设置
        </v-tab>
        <v-tab>
          目标设置
        </v-tab>
        <v-tab>
          动态设置
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-container class="pl-6">
            <v-row class="d-flex align-center ma-0 pa-2">
              <div style="text-align: center; background-color: #53c6c6; width: 28px; height: 28px;border-radius: 4px;">
                <v-icon color="#fff"> mdi-bookmark </v-icon>
              </div>
              <span class="ml-3 text-h6">推荐开启功能</span>
            </v-row>

            <v-row class="d-flex align-center ma-0 pa-0">
              <span class="mr-3 text-subtitle-1">任务审批</span>
              <v-switch v-model="tasksSetting.taskShenpi" @change="onChange"></v-switch>
            </v-row>
            <v-row class="d-flex align-center pa-0 ma-0 pl-5" style="position:relative; top:-15px;">
              <span style="color:#8f9ca6;"> 开启后，可对下属或他人的任务执行变动进行及时管控了解详情 </span>
            </v-row>
            <v-row class="pa-0 ma-0">
              <v-divider></v-divider>
            </v-row>

            <v-row class="d-flex align-center ma-0 pa-2 pt-5">
              <div style="text-align: center; background-color: #f5d540; width: 28px; height: 28px;border-radius: 4px;">
                <v-icon color="#fff"> mdi-checkbox-marked </v-icon>
              </div>
              <span class="ml-3 text-h6">推荐开启功能</span>
            </v-row>

            <v-row class="d-flex align-center ma-0 pa-0">
              <span class="mr-3 text-subtitle-1">任务工时</span>
              <v-switch v-model="tasksSetting.taskWorkingTime" @change="onChange"></v-switch>
            </v-row>
            <v-row class="d-flex align-center pa-0 ma-0 pl-5" style="position:relative; top:-15px;">
              <span style="color:#8f9ca6;"> 开启后，可以随时记录在任务下的花费的时间 </span>
            </v-row>

            <v-row class="pa-0 ma-0">
              <v-divider></v-divider>
            </v-row>
            <v-row class="d-flex align-center ma-0 pa-0">
              <span class="mr-3 text-subtitle-1">任务短信通知</span>
              <v-switch v-model="tasksSetting.taskSms" @change="onChange"></v-switch>
            </v-row>

            <v-row class="pa-0 ma-0">
              <v-divider></v-divider>
            </v-row>
            <v-row class="d-flex align-center ma-0 pa-0">
              <span class="mr-3 text-subtitle-1">代发任务</span>
              <v-switch v-model="tasksSetting.taskDelegate" @change="onChange"></v-switch>
            </v-row>
            <v-row class="d-flex align-center pa-0 ma-0 pl-5" style="position:relative; top:-15px;">
              <span style="color:#8f9ca6;"> 开启后,就可以让别人代替你安排工作给他人 </span>
            </v-row>

            <v-row class="pa-0 ma-0">
              <v-divider></v-divider>
            </v-row>
            <v-row class="d-flex align-center ma-0 pa-0">
              <span class="mr-3 text-subtitle-1">任务参与人</span>
              <v-switch v-model="tasksSetting.taskParticipant" @change="onChange"></v-switch>
            </v-row>

            <v-row class="pa-0 ma-0">
              <v-divider></v-divider>
            </v-row>
            <v-row class="d-flex align-center ma-0 pa-0">
              <span class="mr-3 text-subtitle-1">任务优先级</span>
              <v-switch v-model="tasksSetting.taskPriority" @change="onChange"></v-switch>
            </v-row>

            <v-row class="pa-0 ma-0">
              <v-divider></v-divider>
            </v-row>
            <v-row class="d-flex align-center ma-0 pa-0">
              <span class="mr-3 text-subtitle-1">任务进度</span>
              <v-switch v-model="tasksSetting.taskProgress" @change="onChange"></v-switch>
            </v-row>

            <v-row class="pa-0 ma-0">
              <v-divider></v-divider>
            </v-row>
            <v-row class="d-flex align-center ma-0 pa-0">
              <span class="mr-3 text-subtitle-1">派发任务需要接受</span>
              <v-switch v-model="tasksSetting.taskDispatchAllow" @change="onChange"></v-switch>
            </v-row>

            <v-row class="pa-0 ma-0">
              <v-divider></v-divider>
            </v-row>
            <v-row class="d-flex align-center ma-0 pa-0">
              <span class="mr-3 text-subtitle-1">量化任务</span>
              <v-switch v-model="tasksSetting.taskPrice" @change="onChange"></v-switch>
            </v-row>

            <v-row class="pa-0 ma-0">
              <v-divider></v-divider>
            </v-row>
            <v-row class="d-flex align-center ma-0 pa-0">
              <span class="mr-3 text-subtitle-1">预估工时</span>
              <v-switch v-model="tasksSetting.taskEstTime" @change="onChange"></v-switch>
            </v-row>

            <v-row class="pa-0 ma-0">
              <v-divider></v-divider>
            </v-row>
            <v-row class="d-flex align-center ma-0 pa-0">
              <span class="mr-3 text-subtitle-1">允许参与人修改任务状态</span>
              <v-switch v-model="tasksSetting.taskParticipantAllowStatus" @change="onChange"></v-switch>
            </v-row>

            <v-row class="pa-0 ma-0">
              <v-divider></v-divider>
            </v-row>
            <v-row class="d-flex align-center ma-0 pa-0">
              <span class="mr-3 text-subtitle-1">任务自动完成</span>
              <v-switch v-model="tasksSetting.taskAutoComplete" @change="onChange"></v-switch>
            </v-row>
            <v-row class="d-flex align-center pa-0 ma-0 pl-5" style="position:relative; top:-15px;">
              <span style="color:#8f9ca6;"> 开启后，当任务进度大于等于100%时，系统自动更新任务状态为已完成 </span>
            </v-row>

            <v-row class="pa-0 ma-0">
              <v-divider></v-divider>
            </v-row>

            <v-row class="d-flex align-center ma-0 pa-2 pt-5">
              <div style="text-align: center; background-color: #38ba72; width: 28px; height: 28px;border-radius: 4px;">
                <v-icon color="#fff"> mdi-format-list-bulleted </v-icon>
              </div>
              <span class="ml-3 text-h6">工作计划设置</span>
            </v-row>

            <v-row class="d-flex align-center ma-0 pa-0">
              <span class="mr-3 text-subtitle-1">我的年/季计划表</span>
              <v-switch v-model="tasksSetting.taskMineSchedule" @change="onChange"></v-switch>
            </v-row>

            <v-row class="pa-0 ma-0">
              <v-divider></v-divider>
            </v-row>
            <v-row class="d-flex align-center ma-0 pa-0">
              <span class="mr-3 text-subtitle-1">我的待定任务</span>
              <v-switch v-model="tasksSetting.taskMineSpecTask" @change="onChange"></v-switch>
            </v-row>

            <v-row class="pa-0 ma-0">
              <v-divider></v-divider>
            </v-row>
            <v-row class="d-flex align-center ma-0 pa-0">
              <span class="mr-3 text-subtitle-1">提醒上级查看计划表</span>
              <v-switch v-model="tasksSetting.taskAlarmSuperior" @change="onChange"></v-switch>
            </v-row>

            <v-row class="pa-0 ma-0">
              <v-divider></v-divider>
            </v-row>
            <v-row class="d-flex align-center ma-0 pa-0">
              <span class="mr-3 text-subtitle-1">计划表打印与导出</span>
              <v-switch v-model="tasksSetting.taskPrint" @change="onChange"></v-switch>
            </v-row>

            <v-row class="pa-0 ma-0">
              <v-divider></v-divider>
            </v-row>
            <v-row class="d-flex align-center ma-0 pa-0">
              <span class="mr-3 text-subtitle-1">我的计划表共享 </span>
              <v-switch v-model="tasksSetting.taskMineShare" @change="onChange"></v-switch>
            </v-row>

          </v-container>
        </v-tab-item>
        <v-tab-item>
          <ItemSettingTab/>
        </v-tab-item>
				<v-tab-item>
          <ReportSettingTab/>
        </v-tab-item>
				<v-tab-item>
          <ObjectSettingTab/>
        </v-tab-item>
				<v-tab-item>
          <DongTaiSettingTab/>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </v-row>
</v-container>
</template>

<script>
import {
  mapGetters,
  mapActions
} from "vuex";

export default {
  name: 'advancedFuncPage',
	components: {
    ItemSettingTab: () => import('@/components/admin/advancedFunc/ItemSettingTab.vue'),
		ObjectSettingTab: () => import('@/components/admin/advancedFunc/ObjectSettingTab.vue'),
		DongTaiSettingTab: () => import('@/components/admin/advancedFunc/DongTaiSettingTab.vue'),
		ReportSettingTab: () => import('@/components/admin/advancedFunc/ReportSettingTab.vue'),
  },
  data() {
    return {
      tab: null,
      gettedSetting: {},
      taskSetting: {
        taskShenpi: true, //任务审批
        taskWorkingTime: true, //任务工时
        taskSms: true, //任务短信通知
        taskDelegate: true, //代发任务
        taskParticipant: true, //任务参与人
        taskPriority: true, //任务优先级
        taskProgress: true, //任务进度
        taskDispatchAllow: true, //派发任务需要接受
        taskPrice: true, //量化任务
        taskEstTime: true, //预估工时
        taskParticipantAllowStatus: true, //允许参与人修改任务状态
        taskAutoComplete: true, //任务自动完成
        taskMineSchedule: true, //我的年/季计划表
        taskMineSpecTask: true, //我的待定任务
        taskAlarmSuperior: true, //提醒上级查看计划表
        taskPrint: true, //计划表打印与导出
        taskMineShare: true, //我的计划表共享
      },
    };
  },
  computed: {
    tasksSetting: {
      get() {
        let val = {
          ...this.taskSetting,
          ...this.gettedSetting,
        };
        return val;
      },
      set(newValue) {
      }
    }
  },
  mounted() {
    this.getSettingCall({
      "st_category_id": 2, //tb_task
      "st_category_type": 101 //for this
    }).then(res => {
      if (res) {
        this.gettedSetting = res;
      }
    });
  },
  methods: {
    ...mapActions('app', ['getSettingCall', 'setSettingCall']),
    onChange() {
      this.setSettingCall({
        st_category_id: 2, //tb_task
        st_category_type: 101, //for this
        st_value: this.tasksSetting
      }, ).then(res => {
      });
    },
  }
}
</script>

<style scoped>
</style>
